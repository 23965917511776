import { McMoments } from '../info/types';

export const initMoments: McMoments = {
  biotc: {
    original: '',
    updateTime: 0,
    srcSet: {},
    description: '',
    biotc: false,
    panorama: false,
    portrait: false,
    resolution: '',
  },
  moments: [],
};

export const toggleModalEventName = 'showModal';
export const signIncustomEventName = 'userSignInStatus';
export const swEventName = 'serviceWorkerStatus';
